<template>
  <v-container
    id="project-settings"
    fluid
    tag="section"
    class="grey lighten-4"
  >
    <base-section-heading :title="ap.title" />

    <v-row>
      <!-- Project Roles Card -->
      <v-col>
        <v-card
          class="mt-2 mr-2"
          outlined
        >
          <v-toolbar
            flat
            color="grey"
            dark
            dense
          >
            <v-icon left>
              mdi-account-tie
            </v-icon>
            <v-toolbar-title>{{ $t('project-settings.roles') }}</v-toolbar-title>
            <v-spacer />

            <!-- Add NEW Project User button -->
            <v-btn
              v-if="myRole() === 'manager'"
              color="warning"
              fab
              dark
              @click="dialogRoles = true"
            >
              <v-icon>mdi-account-plus</v-icon>
            </v-btn>
          </v-toolbar>

          <v-card-title>
            <v-spacer />
            <v-spacer />
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              :label="$t('common.search')"
              single-line
              hide-details
            />
          </v-card-title>
          <v-card-text>
            <v-data-table
              :headers="roleheaders"
              :items="assignedRoles"
              :sort-by="['role']"
              :sort-desc="[false]"
              item-key="roleid"
              :items-per-page="4"
              :search="search"
              multi-sort
            >
              <!-- Action buttons -->
              <template v-slot:[`item.actions`]="{ item }">
                <!-- Delete Item -->
                <v-tooltip
                  v-if="cUsersRole === 'manager'"
                  bottom
                >
                  <template #activator="{ on }">
                    <v-icon
                      v-if="item.access !== 'owner'"
                      color="primary"
                      v-on="on"
                      @click="removeRole(item)"
                    >
                      mdi-delete
                    </v-icon>
                  </template>
                  <span>{{ $t('common.delete') }}</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- Data Collectors e.g.App Users Card -->
      <v-col
        lg="5"
        md="6"
        sm="12"
      >
        <v-card
          class="mt-2 mr-2"
          outlined
        >
          <v-toolbar
            flat
            color="primary"
            dark
            dense
          >
            <v-icon left>
              mdi-account-box
            </v-icon>
            <v-toolbar-title>{{ $t('project-settings.data-collectors') }}</v-toolbar-title>
            <v-spacer />

            <!-- Add NEW App User -->
            <v-btn
              v-if="myRole() === 'manager'"
              color="warning"
              fab
              dark
              @click="dialogCollector = true"
            >
              <v-icon>mdi-account-plus</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-title>
            <v-spacer />
            <v-spacer />
            <v-text-field
              v-model="searchdc"
              append-icon="mdi-magnify"
              :label="$t('common.search')"
              single-line
              hide-details
            />
          </v-card-title>
          <v-card-text>
            <v-data-table
              :headers="dcheaders"
              :items="appUsers"
              :search.sync="searchdc"
              :sort-by="['startDate', 'name']"
              :sort-desc="[true, false]"
              item-key="syncDate"
              multi-sort
            >
              <template v-slot:[`item.createdAt`]="{ item }">
                <timeago
                  :datetime="item.createdAt[0]"
                  :locale="$i18n.locale"
                  :auto-update="60"
                />
              </template>
              <!-- Action buttons -->
              <template v-slot:[`item.actions`]="{ item }">
                <!-- QR Code item -->
                <v-tooltip bottom>
                  <template #activator="{ on }">
                    <v-icon
                      class="mr-3"
                      color="indigo"
                      v-on="on"
                      @click="showQrCode(item)"
                    >
                      mdi-qrcode
                    </v-icon>
                  </template>
                  <span>{{ $t('tooltip.qr-see') }}</span>
                </v-tooltip>
                <!-- Revoke User item -->
                <v-tooltip bottom>
                  <template #activator="{ on }">
                    <v-icon
                      class="mr-3"
                      :disabled="myRole() !== 'manager'"
                      color="primary"
                      v-on="on"
                      @click="deleteAppUser(item)"
                    >
                      mdi-account-cancel
                    </v-icon>
                  </template>
                  <span>{{ $t('common.delete') }}</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <!-- Project Forms Card -->
      <v-col>
        <v-card
          class="mt-2 mr-2"
          outlined
        >
          <v-toolbar
            flat
            color="grey"
            dark
            dense
          >
            <v-icon left>
              mdi-cog
            </v-icon>
            <v-toolbar-title>{{ $t('project-list.form-list') }}</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-list-item-group
              v-model="item"
              color="primary"
            >
              <v-list-item
                v-for="(item, i) in pForms"
                :key="i"
                @click="formSelected(item)"
              >
                <v-list-item-content>
                  <v-list-item-title v-text="item.title" />
                </v-list-item-content>
                <v-list-item-content v-if="item.odksettings.phase !== 'active'">
                  <v-list-item-title v-text="`(${item.odksettings.phase})`" />
                </v-list-item-content>
                <v-list-item-content v-else>
                  <v-list-item-title v-text="`(${item.submissions} submissions)`" />
                </v-list-item-content>
                <v-list-item-content>
                  <v-list-item-title v-text="`${item.body}`" />
                </v-list-item-content>
              <!--
              <v-list-item-content>
                <v-tooltip bottom>
                  <template #activator="{ on }">
                    <v-icon
                      class="mr-3"
                      color="indigo"
                      v-on="on"
                      @click="showQrCode(item)"
                    >
                      mdi-cog
                    </v-icon>
                  </template>
                  <span>See QR Code</span>
                </v-tooltip>
              </v-list-item-content>
              -->
              </v-list-item>
            </v-list-item-group>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- ODK Settings Card -->
      <v-col
        lg="4"
        md="6"
        sm="12"
      >
        <odk-settings />
      </v-col>
    </v-row>

    <!-- Bottom buttons -->
    <v-row class="pb-8">
      <!-- Delete Project button  only for staff and managers-->
      <v-btn
        v-if="myAccessLevel() === 'manager-staff'"
        text
        color="error"
        @click="dialogDelete = true"
      >
        <v-icon left>
          mdi-delete
        </v-icon>
        {{ $t('project-settings.del-project') }}
      </v-btn>
      <v-spacer />
      <!-- Close this page -- go back -->
      <v-btn
        text
        @click="goBack()"
      >
        {{ $t('common.close') }}
      </v-btn>
    </v-row>

    <!-- Dialogs -->
    <v-row justify="center">
      <!-- Delete Project Dialog -->
      <v-dialog
        v-model="dialogDelete"
        persistent
        max-width="320"
      >
        <v-card>
          <v-card-title
            v-if="forumCount > 0"
            class="headline font-weight-regular primary white--text py-2"
          >
            {{ $t('common.error') }}
          </v-card-title>
          <v-card-title
            v-else
            class="headline font-weight-regular primary white--text py-2"
          >
            {{ $t('common.heads-up') }}
          </v-card-title>
          <v-card-text v-if="forumCount > 0">
            {{ $t('project-settings.will-del-error') }}
          </v-card-text>
          <v-card-text v-else>
            {{ $t('project-settings.will-del-note') }}
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="grey darken-1"
              text
              @click="dialogDelete = false"
            >
              <div v-if="forumCount > 0">
                {{ $t('common.delete') }}
              </div>
              <div v-else>
                {{ $t('common.cancel') }}
              </div>
            </v-btn>
            <v-btn
              v-if="forumCount === 0"
              color="warning"
              text
              @click="removeProject"
            >
              {{ $t('common.agree') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Show QR Code -->
      <v-dialog
        v-model="dialogQR"
        max-width="250"
      >
        <v-card>
          <!--
          <v-card-title>
            <v-spacer />
            <v-icon
              aria-label="Close"
              @click="dialogQR = false"
            >
              mdi-close
            </v-icon>
          </v-card-title>
          -->

          <v-card-text
            class="text-center ma-0 pa-1"
          >
            <qrcode
              ref="qr"
              :value="qrCode"
              :options="{
                width: 240,
                margin: 1,
              }"
            />
            <v-btn
              color="success"
              icon
              dark
              @click="downloadQrCode()"
            >
              <v-icon
                aria-label="Close"
                @click="dialogQR = false"
              >
                mdi-download
              </v-icon>
              {{ $t('common.download') }}
            </v-btn>
          </v-card-text>

          <!-- <v-card-text
            class="text-center ma-0 pa-1"
          >
            <div @click="downloadQrCode()">
              <qrcode
                ref="qr"
                :value="qrCode"
                :options="{
                  width: 200,
                  margin: 1,
                }"
              />
            </div>
            <v-btn
              color="success"
              icon
              @click="downloadQrCode()"
            >
              <v-icon
                aria-label="Close"
                @click="dialogQR = false"
              >
                mdi-download
              </v-icon>
              {{ $t('common.download') }}
            </v-btn>
          </v-card-text> -->
        </v-card>
      </v-dialog>

      <!-- New Project User -->
      <v-dialog
        v-model="dialogRoles"
        persistent
        max-width="350"
      >
        <v-card>
          <v-card-title>
            <span class="headline">{{ $t('project-settings.new-role') }}</span>
          </v-card-title>

          <!-- Add NEW Project User button -->
          <validation-observer
            v-slot="{ handleSubmit }"
          >
            <form @submit.prevent="handleSubmit(assignRole)">
              <v-card-text class="pb-0">
                <validation-provider
                  v-slot="{ errors }"
                  :name="$t('project-settings.user-name')"
                  rules="required"
                >
                  <v-select
                    v-model="selectedStaff"
                    color="secondary"
                    item-color="secondary"
                    :label="$t('project-settings.user-name')"
                    :items="staffLeft"
                    item-text="name"
                    item-value="uuid"
                    outlined
                    dense
                    return-object
                    :error-messages="errors"
                    prepend-icon="mdi-account"
                  />
                </validation-provider>

                <validation-provider
                  v-slot="{ errors }"
                  :name="$t('project-settings.role')"
                  rules="required"
                >
                  <v-select
                    v-model="selectedRole"
                    color="secondary"
                    item-color="secondary"
                    :label="$t('project-settings.role')"
                    :items="roles"
                    item-text="label"
                    item-value="code"
                    outlined
                    dense
                    return-object
                    :error-messages="errors"
                    :hint="selectedRole.details"
                    persistent-hint
                    prepend-icon="mdi-key-chain-variant"
                  />
                </validation-provider>
              </v-card-text>

              <v-card-actions>
                <v-spacer />
                <v-btn
                  color="grey darken-1"
                  text
                  @click="dialogRoles = false"
                >
                  {{ $t('common.cancel') }}
                </v-btn>
                <v-btn
                  color="success"
                  text
                  type="submit"
                >
                  {{ $t('common.assign') }}
                </v-btn>
              </v-card-actions>
            </form>
          </validation-observer>
        </v-card>
      </v-dialog>

      <!-- Add Data Collector -->
      <v-dialog
        v-model="dialogCollector"
        persistent
        max-width="350px"
      >
        <v-card>
          <v-card-title>
            <span class="headline">Data Collector</span>
          </v-card-title>
          <validation-observer v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(addAppUser)">
              <v-card-text>
                <validation-provider
                  v-slot="{ errors }"
                  :name="$t('table-header.nickname')"
                  :rules="{ required: true, regex: /^[a-zA-Z0-9\s]+$/, max:25 }"
                >
                  <v-text-field
                    v-model="appUserDisplayName"
                    counter="25"
                    outlined
                    dense
                    :error-messages="errors"
                    color="secondary"
                    :label="$t('table-header.nickname')"
                    prepend-icon="mdi-account-box"
                    :hint="$t('hint.letter-numss')"
                  />
                </validation-provider>
                <small class="caption grey--text">{{ $t('project-settings.add-dc-note') }}</small>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  color="grey darken-1"
                  text
                  @click="dialogCollector=false"
                >
                  {{ $t('common.cancel') }}
                </v-btn>
                <v-btn
                  color="success"
                  text
                  type="submit"
                >
                  {{ $t('common.register') }}
                </v-btn>
              </v-card-actions>
            </form>
          </validation-observer>
        </v-card>
      </v-dialog>
    </v-row>

    <!-- Snackbar -->
    <base-material-snackbar
      v-model="snackbar"
      :type="snackbarType"
      v-bind="{
        top: true,
        center: true
      }"
    >
      {{ snackbarText }}
    </base-material-snackbar>
  </v-container>
</template>

<script>
  // import axios from 'axios'

  import Vue from 'vue'
  import VueTimeago from 'vue-timeago'

  import projects from '@/mixins/projects'
  import projectRoles from '@/mixins/project-roles'
  import userAccess from '@/mixins/user-access'
  import utilities from '@/mixins/utilities'
  // import QrcodeVue from 'qrcode.vue'
  import VueQrcode from '@chenfengyuan/vue-qrcode'

  Vue.use(VueTimeago, {
    name: 'Timeago', // Component name, `Timeago` by default
    locale: 'en-US', // Default locale
    locales: {
      pt: require('date-fns/locale/pt'),
      ru: require('date-fns/locale/ru'),
      tr: require('date-fns/locale/tr'),
    },
  })

  Vue.component(VueQrcode.name, VueQrcode)

  export default {
    name: 'ProjectSettings',

    components: {
      OdkSettings: () => import('./components/OdkCollectSettings'),
    },

    mixins: [
      projects,
      projectRoles,
      userAccess,
      utilities,
      // postProjectrole,
      // deleteProjectrole,
    ],

    data: () => ({
      ix: '',
      ap: {},
      // initial form details
      form: {},
      orgid: '',
      prjid: '',
      // selectedProject: '',
      formid: '',

      // json uniq id of selected form
      formjsonid: '',
      formEditIcon: 'mdi-pencil',
      formDescription: '',
      formNameEdit: false,
      // manage update form in the template
      addFormMode: false,
      appUserDisplayName: '',
      addAppUserMode: false,

      roles: [
        { code: 'viewer', label: 'Project Viewer', details: 'Can view, and download all data' },
        { code: 'manager', label: 'Project Manager', details: 'Can view, download all data and manage project settings' },
      ],
      selectedRole: '',
      selectedStaff: '',

      show: false,

      search: undefined,
      searchdc: undefined,
      appUsers: [],

      // Form delete dialog
      dialogQR: false,
      dialogRoles: false,
      dialogDelete: false,
      dialogCollector: false,

      // notification about sync
      snackbar: false,
      snackbarType: 'info',
      snackbarText: '',
      snackbarTimeout: 9000,

      qrCode: '',
      qrCodeDisplayName: '',
      item: 1,
    }),

    computed: {
      // Manual name the form
      qCount () {
        const qcount = 0
        return qcount
      },

      forumCount () {
        const main = this.$store.state.main
        // console.log(main.ap)
        if ('forms' in main.ap) {
          // console.log(main.ap.forms.length)
          return (main.ap.forms.length)
        } else {
          return (0)
        }
      },

      pForms () {
        const main = this.$store.state.main
        if ('forms' in main.ap) {
          // console.log(main.ap.forms[0].odksettings)
          return (main.ap.forms)
        } else {
          return ({})
        }
      },

      // return all other staff except current user
      staffLeft () {
        const main = this.$store.state.main
        const allStaff = this.$store.state.bend.organization.allStaff
        const existingRoles = main.ap.roles
        // https://stackoverflow.com/a/47017949 and https://stackoverflow.com/a/31005753
        const myStaff = allStaff.filter((all) => {
          return !existingRoles.find((ex) => {
            return ex.id === all.uuid
          })
        })
        // console.log(myStaff)
        return myStaff
      },

      // // Check if current user is client. Clients should not see other users of the project
      // isClient () {
      //   return this.$store.state.bend.organization.currentStaff[0].type === 'client'
      // },

      // Check current user's role if viewer add role and data collector button should not be visible
      cUsersRole () {
        // console.log(this.myRole())
        return this.myRole()
      },

      // return table head based on current user's type
      roleheaders () {
        // console.log(this.isClient())
        // console.log(this.roleheaderclient)
        // console.log(this.roleheaderstaff)
        return this.isClient() ? this.roleheaderclient : this.roleheaderstaff
      },

      roleheaderstaff () {
        return [
          { text: this.$t('table-header.staff-name'), value: 'name' },
          { text: this.$t('table-header.role'), value: 'role' },
          { text: this.$t('table-header.type'), value: 'type' },
          { text: this.$t('table-header.actions'), value: 'actions', sortable: false, align: 'end' },
        ]
      },

      roleheaderclient () {
        return [
          { text: this.$t('table-header.staff-name'), value: 'name' },
          { text: this.$t('table-header.role'), value: 'role' },
          { text: this.$t('table-header.actions'), value: 'actions', sortable: false, align: 'end' },
        ]
      },

      dcheaders () {
        return [
          { text: this.$t('table-header.nickname'), value: 'displayName' },
          { text: this.$t('table-header.generated'), value: 'createdAt' },
          // { text: 'Configure Client', value: 'configure' },
          { text: this.$t('table-header.actions'), value: 'actions', sortable: false },
        ]
      },

      // Map user and add access based on account owner and current user
      assignedRoles () {
        const main = this.$store.state.main
        const cStaff = this.$store.state.bend.organization.currentStaff[0]
        const owneruid = this.$store.state.bend.organization.owneruid
        // console.log(main.ap.roles)
        const result = main.ap.roles.map(user => ({
          id: user.id,
          roleid: user.roleid,
          role: user.role,
          name: user.name,
          access: (user.id === owneruid) ? 'owner' : (user.id === cStaff.uuid) ? 'current' : 'otheruser',
          type: this.userType(user.id),
        }))
        // console.log(result)

        let list = []
        // console.log(this.cUsersRole)
        // console.log(cStaff.type)
        if (this.cUsersRole === 'manager' && cStaff.type === 'client') {
          // console.log(cStaff.type)
          list = result.filter(l => l.type === 'client' || l.access === 'owner')
        } else if (this.cUsersRole === 'viewer' && cStaff.type === 'client') {
          list = result.filter(l => l.id === cStaff.uuid)
        } else {
          list = result
        }
        // console.log(list)

        return list
      },

    },

    created () {
      const main = this.$store.state.main
      this.ix = main.ix
      // console.log(this.$refs.OdkSettings)
      this.ap = main.ap

      this.orgid = main.orgid
      this.prjid = main.ap.prjid

      // this.ix = this.$route.params.indexes
      // this.orgid = this.$store.state.bend.organization.orgid
      // this.prjid = this.$store.state.bend.organization.projects[this.ix.pindex].prjid
      // this.selectedProject = this.$store.state.bend.organization.projects[this.ix.pindex]
    },

    beforeDestroy () {
      // this.$store.dispatch('main/reset')
    },

    mounted () {
      // console.log(this.form)
      this.getAppUser()
    },

    methods: {

      // Application User actions
      async getAppUser () {
        const main = this.$store.state.main
        const payload = {}
        payload.orgid = main.orgid
        payload.prjid = main.ap.prjid
        payload.action = 'get'
        this.$store.dispatch('ocpu/appUser', payload).then(
          response => {
            // console.log(response)
            this.appUsers = response.data
          },
          error => {
            return (error)
            // console.log('Got nothing from server via ' + error)
          },
        )
      },

      async addAppUser () {
        const payload = {}
        const main = this.$store.state.main
        payload.orgid = main.orgid
        payload.prjid = main.ap.prjid
        payload.name = this.appUserDisplayName
        payload.action = 'post'
        // console.log(this.$store.state.bend.organization.projects[this.ix.pindex].pid)
        // console.log(payload)
        this.$store.dispatch('ocpu/appUser', payload).then(
          response => {
            // console.log(response)
            // now get all app users from project
            this.getAppUser()
            // payload.name = ''
            // payload.action = 'get'
            // this.$store.dispatch('ocpu/appUser', payload).then(
            //   response => {
            //     // console.log(response)
            //   },
            //   error => {
            //     return (error)
            //     // console.log('Got nothing from server via ' + error)
            //   },
            // )
          },
          error => {
            return (error)
            // console.log('Got nothing from server via ' + error)
          },
        )
        this.dialogCollector = false
      },

      async deleteAppUser (item) {
        const main = this.$store.state.main
        const payload = {}
        payload.orgid = main.orgid
        payload.prjid = main.ap.prjid
        payload.id = item.id[0]
        payload.action = 'delete'
        // console.log(payload)
        this.$store.dispatch('ocpu/appUser', payload).then(
          response => {
            // console.log(response)
            this.getAppUser()
            // this.appUsers = response.data
          },
          error => {
            return (error)
            // console.log('Got nothing from server via ' + error)
          },
        )
      },

      showQrCode (item) {
        this.qrCodeDisplayName = item.displayName[0]
        const updatedQc = this.$store.state.main.qrCode.updated
        const server = this.$store.state.main.ap.odk_server
        // Add server_url value of section general
        updatedQc.general.server_url = `${server}/v1/key/${item.token[0]}/projects/${item.projectId[0]}`
        this.dialogQR = true
        // https://docs.getodk.org/collect-import-export/#list-of-keys-for-all-settings

        var pako = require('pako')
        // encode to Uint8Array
        var Uint8Array = new TextEncoder('utf-8').encode(JSON.stringify(updatedQc))
        // compress
        var bS = pako.deflate(Uint8Array, { to: 'string' })
        // encode base 64
        var bs64 = btoa(bS)
        this.qrCode = bs64

        // // decoding working settings
        // // decode base64
        // var wbin = atob(this.qrCode)
        // console.log(wbin)
        // // decompress
        // var foo = pako.inflate(wbin)
        // console.log(foo)
        // // decode from Uint8Array
        // var string = new TextDecoder('utf-8').decode(foo)
        // console.log(string)
      },

      downloadQrCode () {
        // console.log(this.$refs.qr)
        const img = this.$refs.qr.$el.toDataURL()
        var link = document.createElement('a')
        link.download = `${this.qrCodeDisplayName + '-QR.png'}`
        link.href = img
        // console.log(link)
        document.body.appendChild(link)
        link.click()
        this.dialogQR = false
      },

      // Register a new User of the Project
      async assignRole () {
        // console.log(this.selectedRole.code)
        // console.log(this.selectedStaff.uuid)
        const roledata = {
          role: this.selectedRole.code,
          uuid: this.selectedStaff.uuid,
        }
        await this.postProjectrole(roledata)

        // const newRole = await this.postProjectrole(roledata)
        // console.log(newRole)
        // if (newRole) this.getProjects()
      },

      removeRole (item) {
        // console.log(item)
        this.deleteRole(item.roleid)
      },

      // Get existing projects from Bend
      updateForm () {
        // console.log(this.ix)
        // console.log(this.formjsonid)
        // console.log(this.questions)
        // console.log(this.$store.state.odkform.models)
        // console.log(this.$store.state.odkform.languages)
        this.$store.dispatch('bend/updateOdkForm', {
          ix: this.ix,
          formjsonid: this.formjsonid,
          formsettings: this.settings,
          questions: this.questions,
          models: this.$store.state.odkform.models,
          languages: this.$store.state.odkform.languages,
        })
        this.addFormMode = false
      },

      async removeProject () {
        this.deleteProject()
        this.dialogDelete = false
        // this.goBack()
        this.$router.push({ name: 'Active Forms' })
      },

      // Get QR Code settings from ocpu
      getQcDetails () {
        const ocpuPack = this.$store.state.ocpu.ocpuPack
        return new Promise((resolve, reject) => {
          this.$httpOcpu
            .post(`/${ocpuPack}/R/ocgetqcode/json`, {
              orgid: this.orgid,
              prjid: this.prjid,
            })
            .then(response => {
              // console.log(response)
              // console.log(response.data)
              if (response.status === 201) {
                this.$store.commit('main/setQcUpdated', JSON.parse(response.data))
                this.updateQcDetails()
                resolve(response)
              }
            })
            .catch(error => {
              // console.log(error)
              reject(error)
            })
        })
      },

      updateQcDetails () {
        const main = this.$store.state.main
        const qcDetails = main.qrCode.details
        const updatedQc = main.qrCode.updated

        const updDetails = (prop) => {
          this.$store.commit('main/updateQcDetails', prop)
        }

        const updFunc = (prop) => {
          const u = prop.updated
          const s = prop.section
          // console.log(u)
          // console.log(s)
          // Find and update
          // Take each updated QR Code value to change qcDetails
          Object.keys(u).forEach(function (key, index) {
            // console.log(key)
            // console.log(u[key])

            for (let i = 0; i < s.length; i++) {
              // console.log(i)
              // console.log(s[i].items)
              // s.forEach((item, index) => {

              const pos = s[i].items.findIndex(item => item.name === key)
              // if found ...
              if (pos >= 0) {
                // ... get the selected option's object
                if (s[i].items[pos].dialog === 'single') {
                  // If this a single select radoibox
                  const selected = s[i].items[pos].options.filter(obj => {
                    return obj.name === u[key]
                  })
                  // update Details
                  const payload = { i: i, pos: pos, selected: selected[0], type: 'single' }
                  updDetails(payload)
                  break
                } else {
                  // If this is a single true/false checkbox
                  const payload = {
                    i: i,
                    pos: pos,
                    selected: s[i].items[pos].forchange,
                    forchange: s[i].items[pos].selected,
                    type: 'checkbox',
                  }
                  updDetails(payload)
                  if (s[i].items[pos].selected.value === u[key]) {
                    qcDetails.general[i].items[pos].selected = s[i].items[pos].selected
                  } else {
                    qcDetails.general[i].items[pos].selected = s[i].items[pos].forchange
                  }
                }
              }
            }
          })
        }
        const prop = { updated: updatedQc.general, section: qcDetails.general }
        updFunc(prop)
      },

      // Prepare QC Details and send to save function
      prepQcDetails (prop) {
        const updatedQc = this.$store.state.main.qrCode.updated
        updatedQc[prop.group][prop.subgroup] = prop.value
        const qcsettings = JSON.stringify(updatedQc)
        this.saveQcDetails(qcsettings)
      },

      // Save QC Details to ocpu
      saveQcDetails (qcsettings) {
        const ocpuPack = this.$store.state.ocpu.ocpuPack
        return new Promise((resolve, reject) => {
          this.$httpOcpu
            .post(`/${ocpuPack}/R/ocsaveqcode/json`, {
              settings: qcsettings,
              orgid: this.orgid,
              prjid: this.prjid,
            })
            .then(response => {
              // console.log(response)
              if (response.status === 201) {
                this.getQcDetails()
                resolve(response)
              }
            })
            .catch(error => {
              // console.log(error)
              reject(error)
            })
        })
      },

      formSelected (item) {
        // console.log(item)
        this.$store.dispatch('main/setIndexes', item.ix).then(() => {
          this.$router.push({ name: 'Dashboard' })
        })
      },

      // Manual name the form
      editName () {
        this.formNameEdit = !this.formNameEdit
        if (this.formNameEdit) {
          this.formEditIcon = 'mdi-restore'
        } else {
          this.settings.id = this.settings.title.replace(/\W/g, '')
          this.formEditIcon = 'mdi-pencil'
        }
      },

      // Auto name the form id based on the form Title
      formAutoName () {
        // Forms id's are fixed. Use following code when we allow multi forms.
        if (!this.formNameEdit) {
          this.settings.id = this.settings.title.replace(/\W/g, '')
        }
      },

      noSpace () {
        this.settings.id = this.settings.id.replace(/\W/g, '')
      },

      settingsHelp () {
        // console.log(this.settings.form_id)
      },
    },
  }

</script>

<style lang="sass">

</style>
